var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-container-widget',{attrs:{"id":"tasks","app-id":2,"view-more-location":_vm.getLocation('tasks'),"disable-view-more":_vm.disableViewMore,"size":_vm.addOnSize,"stretch":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ViewAllButton',{attrs:{"item-type":"tasks"}})]},proxy:true}])},[[(_vm.areFiltersVisible)?_c('div',{staticClass:"flex-column flex-md-row justify-content-center filters-container mb-2 px-50 pt-1"},[_c('div',{staticClass:"d-flex flex-column flex-md-row filters-container mb-2 px-50 pt-1"},[_c('b-form-group',{staticClass:"mr-1 w-100"},[_c('label',{attrs:{"for":"classifier-filter"}},[_vm._v(_vm._s(_vm.$t("filterType")))]),_c('v-select',{staticStyle:{},attrs:{"name":"classifier-filter","clearable":"","label":"name","reduce":function (a) { return a; },"options":_vm.finalOptions},on:{"input":_vm.setSeletedFilter},model:{value:(_vm.selectedClassifiersForFilter),callback:function ($$v) {_vm.selectedClassifiersForFilter=$$v},expression:"selectedClassifiersForFilter"}})],1),(_vm.nextFilter != null)?_c('b-form-group',{staticClass:"mr-1 w-100"},[(_vm.nextFilter.type || _vm.nextFilter[0] != null)?_c('label',{attrs:{"for":"custom-filter"}},[_vm._v("Value")]):_vm._e(),(_vm.nextFilter[0] != null)?_c('v-select',{attrs:{"name":"custom-filter","clearable":"","label":"name","reduce":function (nextFilter) { return nextFilter; },"options":_vm.nextFilter},on:{"input":_vm.setSeletedFinalFilter},model:{value:(_vm.selectedCustomFieldsForFilter),callback:function ($$v) {_vm.selectedCustomFieldsForFilter=$$v},expression:"selectedCustomFieldsForFilter"}}):_vm._e(),(_vm.nextFilter.type === 2)?_c('b-form-input',{staticClass:"w-100",staticStyle:{"height":"33.18px"},attrs:{"placeholder":"Start typing..."},on:{"keyup":function($event){return _vm.handleChanger(null)},"input":function($event){return _vm.setSeletedFinalFilter({
                text: $event,
                type: 2,
                key: _vm.nextFilter.key,
              })}},model:{value:(_vm.customFieldToSend[_vm.nextFilter.key]),callback:function ($$v) {_vm.$set(_vm.customFieldToSend, _vm.nextFilter.key, $$v)},expression:"customFieldToSend[nextFilter.key]"}}):_vm._e()],1):_vm._e()],1)]):_vm._e()],(_vm.isLoading)?_c('div',{staticClass:"mb-5"},[_c('b-spinner',{staticClass:"mx-auto mt-3 d-block",attrs:{"type":"grow","small":"","variant":"primary"}})],1):(_vm.suggestions.length > 0)?_c('div',[_c('b-row',{staticClass:"todo-app-list mt-3"},[_c('b-col',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.suggestions,"responsive":"","fields":_vm.fields,"primary-key":"id","show-empty":"","empty-text":"loading"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
              var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center"},[_c('b-link',{staticClass:"text-dark d-flex mt-50",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('p',{staticClass:"mb-0 ml-1 pl-25 truncated-text"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)]}},{key:"cell(suggestion)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',[_c('p',{staticClass:"mb-0 ml-1 pl-25 truncated-text"},[_vm._v(" "+_vm._s(item.message)+" ")])])])]}},{key:"cell(user)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[(item.assignee)?_c('div',{staticClass:"text-nowrap"},[_c('p',{staticClass:"mb-0"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"title":((item.assignee.name) + " " + (item.assignee.surname) + " "),"size":"40","src":_vm.getImageSrc(item.assignee.avatarURLl)}}),_vm._v(" "+_vm._s(item.assignee.name)+" "+_vm._s(item.assignee.surname)+" ")],1)]):_c('span',{staticClass:"text-muted ml-2 mt-50"},[_vm._v("---")])])]}},{key:"cell(priority)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-edit-priority",modifiers:{"modal-edit-priority":true}}],staticClass:"mt-1 cursor-pointer ml-2",staticStyle:{"outline-style":"none"},attrs:{"icon":"flag-fill","scale":"1.3","variant":_vm.resolveTagVariant(item.priority)}}),_c('span',{staticClass:"mt-50 ml-50"},[_c('small',[_vm._v(_vm._s(_vm.getTranslatedPriority(item.priority)))])])],1)]}},{key:"cell(status)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex mt-50",attrs:{"to":_vm.getSuggestionLocation(item)}},[_c('div',{staticClass:"text-nowrap"},[_c('p',{staticClass:"mb-0"},[_c('b-badge',{staticClass:"text-capitalize ml-1",attrs:{"pill":"","variant":("" + (_vm.resolveStatusVariant(item.status)))}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])],1)])])]}},{key:"cell(creator)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex mt-50",attrs:{"to":_vm.getSuggestionLocation(item)}},[(item.ownedByMember)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.ownedByMember.name)+" "+_vm._s(item.ownedByMember.surname))]):_c('span',{staticClass:"text-muted ml-2"},[_vm._v("---")])])]}},{key:"cell(date)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex mt-50",attrs:{"to":_vm.getSuggestionLocation(item)}},[(item.dueDate)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(new Date(item.dueDate).toLocaleDateString()))]):_c('span',{staticClass:"text-muted ml-2"},[_vm._v("---")])])]}},{key:"cell(attach)",fn:function(ref){
              var item = ref.item;
return [_c('b-link',{staticClass:"text-dark d-flex mt-50",attrs:{"to":_vm.getSuggestionLocation(item)}},[(item.attachments && item.attachments.length > 0)?_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"PaperclipIcon","size":"16"}}):_vm._e()],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.total,"per-page":8,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1):_c('b-row',{staticClass:"horizontal-placeholder"},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.placeholder}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t("available.message", { itemName: _vm.$t("tasks.title") }))+" ")])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }